.collection {
  &__wrapper {
    padding-top: 30px;
    &__filterWrapper {
      position: relative;
      // HERE
      &__sortParent {
        position: absolute;
        padding: 5px;
        background-color: #fff;
        left: 125px;
        border-radius: 5px;
        box-shadow: 1px 1px 5px 6px rgba(0, 0, 0, 0.09);
        box-sizing: border-box;
        padding: 2px 10px;
        transition: all 0.2s ease;
        top: 130px;
        &__item {
          padding: 4px;
          font-family: sans-serif;
          font-size: 14px;
          margin: 8px 0px;
          cursor: pointer;
          &:hover {
            background-color: #eee;
          }
        }
        &__item {
          padding: 4px;
          font-family: sans-serif;
          font-size: 14px;
          margin: 8px 0px;
          cursor: pointer;

          &:hover {
            background-color: #eee;
          }
        }
        // opacity: 0;
        transform: scale(0);
        z-index: 9999 !important;
        &--open {
          z-index: 9999 !important;
          transform: scale(1);
          // opacity: 1;
        }
      }
      &__parent {
        font-size: 13px;
        padding: 0px 15px 20px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__left {
          font-family: sans-serif;
          display: flex;
          align-items: center;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          gap: 5px;
          &__icon {
            font-size: 16px;
            padding: 0px;
          }
        }
        &__right {
          font-family: sans-serif;
        }
      }
    }
    &__products {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      &__item {
        padding-left: 5px;
        width: 24%;
        @media screen and (max-width: 768px) {
          width: 48%;
        }
      }
    }
  }
}
