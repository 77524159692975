.mobileHeader {
  &__wrapper {
    padding: 55px 20px;
    z-index: 9999;
    left: -100%;
    top: 0;
    position: fixed;
    width: 90%;
    height: 100vh;
    transition: all 0.4s ease;
    background-color: #000;
    display: flex;
    flex-direction: column;
    &--show {
      @media screen and (max-width: 768px) {
        left: 0;
      }
    }
  }
  &__close {
    position: absolute;
    right: 30px;
    top: 30px;
    color: #fff;
    cursor: pointer;
  }
}
