.search {
  &__container {
    background-color: white;
    overflow: hidden;
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 99999;
    display: flex;
    align-items: center;
    width: 100%;
    height: 0px;
    transition: height 0.2s ease-in-out;
    &--show {
      overflow: hidden;
      height: 80px;
    }
    &__fieldWrapper {
      padding: 20px 45px 20px 30px;

      flex: 1;
      position: relative;
      @media screen and (min-width: 768px) {
        max-width: 900px;
        margin: auto;
      }
      &__field {
        width: 100%;
        padding: 15px 10px;
        border: 1px solid #e7e6e0;
        outline: none;
        color: #5c5c5c;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font-family: "ITC New Veljovic", serif;
        font-weight: 400;
        font-size: 9px;
        &::placeholder {
          color: #939393;
        }
      }

      &__searchIcon {
        position: absolute;
        right: 55px;
        top: 32px;
        font-size: 22px;
        color: #939393;
        @media screen and (max-width: 768px) {
          right: 55px;
        }
      }
      &__closeIcon {
        position: absolute;
        top: 33px;
        font-size: 22px;
        color: #939393;
        cursor: pointer;
        right: 15px;
        -webkit-tap-highlight-color: transparent;
        @media screen and (max-width: 768px) {
        }
      }
    }
  }
  &__results {
    position: fixed;
    width: 100%;
    background-color: #fff;
    z-index: 999999;
    top: 80px;
    padding: 10px 20px;
    &__title {
      font-size: 10px;
      color: #393939;
      margin: 0px;
      padding: 0px 0px 10px 20px;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
    }
  }
}
