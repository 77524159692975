.orderReceived {
  &__wrapper {
    padding-top: 30px;
    max-width: 600px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
