.radioInput {
  &__wrapper {
    cursor: pointer;
    padding: 20px 12px;
    border-radius: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    border-bottom: 1px solid #e7e6e0;
    &__left {
      display: flex;
      align-items: center;
      gap: 12px;
      &__checkbox:checked {
      }
      &__description {
        font-weight: 400;
        color: #5c5c5c;
        margin: 0px;
        font-size: 15px;
      }
    }
    &__right {
      &__price {
        margin: 0;
        color: #5c5c5c;
        font-size: 14px;
      }
    }
  }
}
.checked {
  border: 1px solid #1773b0;
  background-color: #1773b01d;
}
