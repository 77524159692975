.searchItem {
  margin-bottom: 20px;
  cursor: pointer;
  &__wrapper {
    display: flex;
    &__img {
      width: 90px;
      object-fit: cover;
    }
    &__name {
      color: #393939;
      padding-left: 2px;
      font-size: 13px;
    }
  }
}
