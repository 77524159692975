.contact {
  width: 100%;

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
    &__form {
      padding: 15px;
      padding-top: 30px;
      display: flex;
      gap: 20px;
      flex-direction: column;
      width: 100%;
      max-width: 500px;
      &__btn {
        margin-top: 30px;
        align-self: center;
        max-width: 250px;
      }
    }
    &__subtitle {
      padding: 20px 15px 0px 15px;
      font-size: 10px !important;
      text-align: start;
    }
    &__subtitle--2 {
      padding: 5px 15px 0px 15px;
      font-size: 10px !important;
      text-align: start;
    }
  }
}
