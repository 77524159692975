.mobileNavItem {
  width: 100%;
  &__wrapper {
    transform: translateX(-50px);
    width: 100%;
    border-bottom: 1px solid rgba(255, 254, 247, 0.25);
    transition: 0.6s all ease-in-out;
    &--show {
      transform: translateX(0px);
    }
    &__label {
      display: block;
      position: relative;
      width: 100%;
      padding: 20px 0;
      text-align: left;
      cursor: pointer;
      font-size: 12px;
      font-weight: 400;
      color: #fffef7;
      font-family: "ITC New Veljovic", sans-serif;
      margin: 0px;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
    }
    &__list {
      overflow: hidden;
      // margin-bottom: 10px;
      padding-left: 16px;
      &__item {
        @extend .mobileNavItem__wrapper__label;
        padding: 13px 0px;
        color: rgba(255, 254, 247, 0.5);
      }
      transition: height 0.6s ease-in-out;
      &--show {
        // height: 170px;
        margin-bottom: 10px;
      }
      height: 0px;
    }
    &__flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__expand {
      font-size: 12px;
      color: #fff;
      cursor: pointer;
    }
  }
}
