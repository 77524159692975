.productsSlider {
  &__wrapper {
    padding-top: 50px;
    &__slider {
      padding-top: 50px;
      &__slideItem {
        padding-left: 5px;
        &__img {
          // object-fit: cover;
          @media screen and (max-width: 450px) {
            max-height: 300px !important;
          }
          @media screen and (max-width: 350px) {
            max-height: 250px !important;
          }
        }
      }
    }
    &__btn {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      margin: 30px auto auto;
      padding: 18px 30px;
      background-color: #000;
      color: #eee;
      // font-size: 12px;
    }
  }
}
