.about {
  &__wrapper {
    text-align: start !important;
    padding: 30px 10px;
    &__paragraph {
      padding-top: 40px;
      font-size: 12px;
      word-break: break-word;
      color: #5c5c5c;
      line-height: 1.65;
      max-width: 800px;
      margin: auto;
    }
  }
}
.policy__list {
  padding-top: 10px;
  li {
    text-align: start;
    margin: 10px 0px;
  }
}
