.about {
  &__wrapper {
    text-align: center;
    padding: 30px 10px;
    &__paragraph {
      padding-top: 40px;
      font-size: 12px;
      word-break: break-word;
      color: #5c5c5c;
      line-height: 1.65;
      max-width: 800px;
      margin: auto;
    }
  }
}
