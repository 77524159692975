@mixin colorHandle($color) {
  color: $color;
}

.container {
  padding-bottom: 74px;
}

.header--desktop {
  // margin-bottom: -74px;
  background-color: transparent;
  position: fixed;
  z-index: 9999;
  width: 100%;

  &__wrapper {
    padding: 0px 40px;

    @media screen and (max-width: 768px) {
      padding: 0px 10px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__hamburger {
      @extend .header--desktop__wrapper__icons__icon;
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    &__links {
      display: flex;

      @media screen and (max-width: 768px) {
        display: none;
      }

      align-items: center;
      gap: 15px;
      flex: 1;

      &__dropdown {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        top: 40px;
        padding: 15px 0;
        min-width: 200px;
        max-width: 270px;
        width: max-content;
        text-align: left;
        background: #fff;
        z-index: 99999;
        border: 1px solid #d9d8d2;
        border-top: none;
        transition: all 0.3s ease-in-out;

        &__item {
          text-decoration: none;
          list-style: none;
          padding-left: 25px;
          padding-right: 40px;
          display: block;
          width: 100%;
          text-align: inherit;
          line-height: 1.5;
          font-weight: 400;
          cursor: pointer;
          font-size: 11px;
          margin-bottom: 5px;
          color: #111;
        }
      }

      &__link {
        font-size: 10px;
        color: #fff;
        font-weight: 400;
        text-transform: uppercase;
        font-family: "ITC New Veljovic", sans;
        cursor: pointer;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        height: 100%;

        // padding: 18px 10px;
        &__label {
          color: white;
          // padding: 2px;
          padding: 18px 10px;
        }
      }

      &__link:hover~&__dropdown,
      &__dropdown:hover {
        opacity: 1;
        visibility: visible;
      }
    }

    &__title {
      flex: 1;
      color: #fff;
      font-family: "Oswald", sans-serif, sans-serif !important;
      font-size: 23px;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-align: center;
    }

    &__icons {
      @media screen and (min-width: 769px) {
        flex: 1;
      }

      justify-content: flex-end;
      display: flex;
      align-items: center;
      gap: 10px;

      &__icon {
        color: white;
        cursor: pointer;
        font-size: 19px;

        &.ti-bag.isProducts {
          position: relative;

          &:after {
            content: "";
            position: absolute;
            border-radius: 50%;
            // padding: 5px;
            background-color: #fff;
            right: -3px;
            top: 2px;
            width: 7px;
            height: 7px;
            border-radius: 100%;
          }
        }
      }
    }
  }

  &--v2 {

    // padding: 0px 40px;
    .header--desktop__wrapper {
      padding: 0px 40px;

      @media screen and (max-width: 768px) {
        padding: 0px 10px;
      }
    }

    background-color: white;
    margin-bottom: 0px;
    border-bottom: 1px solid #dddddd95;

    .header--desktop__wrapper__hamburger,
    .header--desktop__wrapper__links__link,
    .header--desktop__wrapper__title,
    .header--desktop__wrapper__icons__icon,
    .header--desktop__wrapper__links__link__label {
      color: black;
    }

    .ti-bag.isProducts {
      position: relative;

      &:after {
        background-color: #000;
      }
    }
  }

  transition: all 0.3s ease-in;

  &--scroll {
    @extend .header--desktop--v2;
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 999999999;
    top: 0;
  }
}