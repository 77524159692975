.copyright {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 15px;
  &__label {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.897);
    &__link {
      color: white;
      border-bottom: 1px solid white; /* Adjust the thickness and color as needed */
      padding-bottom: 1px; /* Adjust the spacing as needed */
      font-weight: bold;
    }
  }
}
