.payment {
  &__wrapper {
    padding: 20px;
    max-width: 500px;
    margin: auto;
    &__details {
      border-radius: 8px;
      border: 1px solid #e7e6e0;
      padding: 0px 15px;
      &__item {
        padding: 15px 0px;
        border-bottom: 1px solid #e7e6e0;
        display: flex;
        align-items: center;
        gap: 20px;
        &__title {
          color: #5c5c5c;
          font-size: 13px;
        }
        &__label {
          font-size: 13px;
        }
      }
    }
  }
}
