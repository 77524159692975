.hero {
  width: 100%;
  .swiper-pagination-bullet {
    background-color: transparent !important;
    border: 1px solid #fff;
    padding: 3px;
  }
  .swiper-pagination-bullet-active {
    @extend .swiper-pagination-bullet;
    background-color: white !important;
  }
  .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    width: unset;
    padding-left: 20px;
  }

  &__wrapper {
    &__background {
      width: 100%;
      // background: url("https://cdn.shopify.com/s/files/1/0730/3793/8984/files/Snapinsta.app_1080_316991775_133941852792490_7444164708601025860_n_4100x.jpg?v=1678526804 4100w");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100vh;
      position: relative;
      display: flex;
      align-items: flex-end;
      &__content {
        padding: 0px 0px 35px 20px;
        &__btn {
          background-color: #fff;
          color: #000;
          padding: 15px 30px !important;
        }
        &__title {
          text-align: left !important;
        }
      }
    }
  }
}
@keyframes Fadeout {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
