.card {
  width: 100%;
  &__wrapper {
    display: flex;
    flex-direction: column;
    &__img {
      height: 300px;
      object-fit: cover;
      @media screen and (max-width: 450px) {
        max-height: 200px;
      }
      @media screen and (max-width: 350px) {
        max-height: 170px;
      }
    }
    &__name {
      letter-spacing: 2px;
      color: #5c5c5c !important;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 11px !important;
    }
    &__price {
      @extend .card__wrapper__name;
    }
  }
}
