.homeCustomSection {
  margin-top: 50px;
  width: 100%;
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
    &__item {
      // background-image: url("https://cdn.shopify.com/s/files/1/0558/7150/4556/files/2_1500x.jpg?v=1674372360%201500w");
      background-position: center;
      background-size: cover;
      min-height: 400px;
      min-width: 250px;
      flex: 1;
      display: flex;
      align-items: flex-end;
      padding: 0 0 30px 30px;
      &__title {
        text-align: left !important;
      }
    }
  }
}
