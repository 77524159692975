.subTitle {
  margin: 0px;
  font-size: 12px !important;
  color: #5c5c5c;
  font-weight: 400 !important;
  letter-spacing: 2.4px;
  margin-top: 0;
  text-align: center;
  display: block;
  margin-bottom: 5px;
  line-height: 25px;
}
