.information {
  &__wrapper {
    padding: 20px;
    display: flex;
    gap: 30px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }
    max-width: 1000px;
    margin: auto;
    // align-items: center;
    &__title {
      text-align: left;
      padding: 20px 20px 0px 20px;
      max-width: 1000px;
      margin: auto;
    }
    &__select {
      padding: 15px 10px;
      border: 1px solid #e7e6e0;
      background-color: transparent;
      width: 100%;
    }
    &__left {
      flex: 1;
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 15px;
    }
    &__right {
      flex: 0.7;
      width: 100%;
      display: flex;
      flex-direction: column;
      // margin-top: -18px;

      &__btn {
        @media screen and (max-width: 768px) {
          order: 1;
        }
        margin-top: 20px;
        @media screen and (max-width: 768px) {
          margin-top: 0px;
        }
        // display: flex;
        width: 100%;
        // justify-content: center;
        &__radio {
          border: 1px solid #e7e6e0;
          border-radius: 6px;
          margin-bottom: 10px;
          overflow: hidden;
        }
        &--btn {
          @media screen and (max-width: 768px) {
            order: 10 !important;
            margin-top: 15px;
          }
          width: 100%;
          background-color: black;
          color: #fffef7;
          padding: 15px 20px;
        }
      }
    }
    &__couponLabel {
      font-size: 13px;
      // color: #5c5c5c;
      color: #000;
      margin: 0px;
      cursor: pointer;
      text-decoration: underline;
      font-weight: 500;
    }
  }
}
