.spinner {
  height: 8px;
  background-color: #eee;
  width: 100px;
  position: relative;
}

.spinner::after {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  left: 0;
  content: "";
  transition: all .7s linear;
  animation: 400ms Barloader infinite linear;
  transform-origin: left;

}

@keyframes Barloader {
  from {
    width: 0px;
  }

  to {
    width: 100px;
  }
}

.loading-bg {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  left: 0;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}