.cardInCart {
  margin-bottom: 20px;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    &__details {
      flex: 1;
      padding-left: 10px;
      &__name {
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font-size: 13px;
        white-space: nowrap;
        font-weight: 400;
        color: #5c5c5c;
        overflow: hidden;
        max-width: 280px;
        @media screen and (max-width: 768px) {
          max-width: 200px;
        }
        @media screen and (max-width: 360px) {
          max-width: 150px;
        }
        text-overflow: ellipsis;
      }
      &__variant {
        margin-bottom: 0.45em;
        font-size: 8px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #939393;
      }
      &__price {
        margin-bottom: 0.45em;
        font-size: 8px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #939393;
        padding-bottom: 5px;
      }
      &__controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__icon {
          color: crimson;
          cursor: pointer;
        }
      }
    }
  }
}
