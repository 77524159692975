.cart {
  &__wrapper {
    padding: 15px;
    z-index: 9999;
    right: -100%;
    top: 0;
    position: fixed;
    width: 410px;
    @media screen and (max-width: 410px) {
      width: 90%;
    }
    height: 100dvh;
    transition: all 0.4s ease-in-out;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    &--show {
      right: 0px;
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__label {
        font-size: 15px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #5c5c5c;
        font-weight: 400;
        font-family: "ITC NEW Veljovic", sans-serif;
      }
      &__icon {
        cursor: pointer;
      }
    }
    &__body {
      flex: 1;
    }
    &__footer {
      &__btn {
        margin-bottom: 10px;
        &__label {
          min-width: 100%;
        }
      }
    }
    &__whenEmpty {
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      &__label {
        font-size: 13px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #5c5c5c;
        font-weight: 400;
      }
    }
  }
}
