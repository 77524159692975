.notFound {
  &__iconWrapper {
    padding-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
      font-size: 50px;
    }
  }
  &__label {
    padding-top: 20px;
    text-align: center;
  }
}
