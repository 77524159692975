.productGrid {
  margin-top: 60px;
  width: 100%;
  &__wrapper {
    padding-top: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    &__item {
      padding-left: 5px;
      width: 24%;
      @media screen and (max-width: 768px) {
        width: 48%;
      }
    }
  }
}
