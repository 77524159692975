.btn {
  &__fancy {
    z-index: 99;
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    letter-spacing: 0.05em;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 1em 2em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: all 0.3s ease-in-out;
    user-select: none;
    font-size: 13px;
    &::before {
      content: " ";
      width: 1.1rem;
      height: 2px;
      background: black;
      top: 50%;
      left: 1.5em;
      position: absolute;
      transform: translateY(-50%);
      transform-origin: center;
      transition: background 0.3s linear, width 0.3s linear;
    }
    &__text {
      font-size: 1.125em;
      line-height: 1.33333em;
      padding-left: 2em;
      display: block;
      transition: all 0.3s ease-in-out;
      text-decoration: none;
      color: black;
    }
    &__top-key {
      height: 2px;
      width: 1.5625rem;
      top: -2px;
      left: 0.625rem;
      position: absolute;
      background: #e8e8e8;
      transition: width 0.5s ease-out, left 0.3s ease-out;
    }

    &__bottom-key-1 {
      height: 2px;
      width: 1.5625rem;
      right: 1.875rem;
      bottom: -2px;
      position: absolute;
      background: #e8e8e8;
      transition: width 0.5s ease-out, right 0.3s ease-out;
    }

    &__bottom-key-2 {
      height: 2px;
      width: 0.625rem;
      right: 0.625rem;
      bottom: -2px;
      position: absolute;
      background: #e8e8e8;
      transition: width 0.5s ease-out, right 0.3s ease-out;
    }

    &:hover {
      color: white;
      background: black;
    }

    &:hover::before {
      width: 0.9375rem;
      background: white;
    }

    &:hover &__text {
      color: white;
      padding-left: 1.5em;
    }

    &:hover &__top-key {
      left: -2px;
      width: 0px;
    }

    &__:hover &__bottom-key-1,
    &__:hover &__bottom-key-2 {
      right: 0;
      width: 0;
    }
  }
  &__classic {
    &__wrapper {
      padding: 5px;
      &__btn {
        padding: 13px 20px;
        cursor: pointer;
        border: none;
      }
    }
  }
}
