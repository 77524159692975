$text-color: #fffef7;
.heroContent {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    &__subtitle {
      color: $text-color;
      text-align: left;
      font-size: 11px !important;
    }
    &__title {
      color: $text-color;
      font-size: 19px !important;
    }
  }
}
