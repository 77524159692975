$text-color: #9b9090;
$background-color: #000;

.footer {
  margin-top: 60px;
  width: 100%;
  padding: 54px 0;
  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
  background: $background-color;
  font-family: "ITC New Veljovic";
  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 30px;
    }
    padding: 0px 30px;
    &__links {
      flex: 0.7;
      &__title {
        font-size: 14px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: $text-color;
        padding-bottom: 8px;
        border-bottom: 1px solid #eee;
        width: fit-content;
      }
      &__list {
        &__item {
          font-size: 11px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          display: block;
          margin-bottom: 20px;
          color: $text-color;
        }
      }
    }
    &__about {
      flex: 1;
      &__title {
        @extend .footer__wrapper__links__title;
      }
      &__paragraph {
        font-size: 12px;
        color: $text-color;
        color: $text-color;
        max-width: 80%;
        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
    &__newsletter {
      flex: 1;
      &__title {
        @extend .footer__wrapper__links__title;
      }
      &__subtitle {
        color: $text-color;
        font-size: 11px;
      }
      &__form {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        @media screen and (max-width: 350px) {
          flex-direction: column;
        }
        &__icon {
          color: #000;
          position: absolute;
          z-index: 9999;
          right: 10px;
          top: 30%;
        }
        &__field {
          background-color: #333;
          color: white;
        }
        &__btn {
          background-color: white;
          padding: 14.5px 20px;
          &:hover {
            background-color: #eee;
          }
        }
      }
    }
  }
}
