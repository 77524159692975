p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
button,
li,
span {
  font-family: "ITC New Veljovic", serif !important;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

.tas {
  text-align: start;
  font-weight: bold;
}

/* @font-face {
  font-family: "ITC New Veljovic";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("https://fonts.shopifycdn.com/itc_new_veljovic/itcnewveljovic_n4.1a24c88d3830051a67d101f1f4b0b8536d4f266c.woff2?h1=dGhldnliZS1jby5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=dGhldnliZS5jbw&hmac=4df49197fab9a7c66bf12c462e720f9722455cd70416c2f84621dec935a5ddce")
      format("woff2"),
    url("https://fonts.shopifycdn.com/itc_new_veljovic/itcnewveljovic_n4.f11899c56fe9ba10c79111c4dfde09cb8349d269.woff?h1=dGhldnliZS1jby5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=dGhldnliZS5jbw&hmac=dbe2d9addad4d74266fd48a05266cc5230b32ba73631f2051e3b2dc1d450f8d0")
      format("woff");
}

@font-face {
  font-family: "ITC New Veljovic";
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  src: url("https://fonts.shopifycdn.com/itc_new_veljovic/itcnewveljovic_n4.1a24c88d3830051a67d101f1f4b0b8536d4f266c.woff2?h1=dGhldnliZS1jby5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=dGhldnliZS5jbw&hmac=4df49197fab9a7c66bf12c462e720f9722455cd70416c2f84621dec935a5ddce")
      format("woff2"),
    url("https://fonts.shopifycdn.com/itc_new_veljovic/itcnewveljovic_n4.f11899c56fe9ba10c79111c4dfde09cb8349d269.woff?h1=dGhldnliZS1jby5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=dGhldnliZS5jbw&hmac=dbe2d9addad4d74266fd48a05266cc5230b32ba73631f2051e3b2dc1d450f8d0")
      format("woff");
}

@font-face {
  font-family: "ITC New Veljovic";
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
  src: url("https://fonts.shopifycdn.com/itc_new_veljovic/itcnewveljovic_n7.f55c5c0ce378ed14267ebacce75cf494ef18c257.woff2?h1=dGhldnliZS1jby5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=dGhldnliZS5jbw&hmac=db29750c453ab613fcf924e0c7445208e4391deaf9dee89a92e44e2b3a3d301b")
      format("woff2"),
    url("https://fonts.shopifycdn.com/itc_new_veljovic/itcnewveljovic_n7.587b2c55f780658350468b568c6cbcc16c88a6ba.woff?h1=dGhldnliZS1jby5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=dGhldnliZS5jbw&hmac=293c0b94be65bf98a5834ba8d0b37e60af9d1243e76929a6d7ca854952da6056")
      format("woff");
}

@font-face {
  font-family: "ITC New Veljovic";
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
  src: url("https://fonts.shopifycdn.com/itc_new_veljovic/itcnewveljovic_i4.7ce2635921e94b87d2284a17a8a4a120119b8317.woff2?h1=dGhldnliZS1jby5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=dGhldnliZS5jbw&hmac=9929a75ef36ff45796a465c7969b9b7e987bdb3ec459adc6b04c655b7cafb2e9")
      format("woff2"),
    url("https://fonts.shopifycdn.com/itc_new_veljovic/itcnewveljovic_i4.f232e756edcab6930e7ef9eb4fcd5772e08172cd.woff?h1=dGhldnliZS1jby5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=dGhldnliZS5jbw&hmac=63a8ebb1296f571f04daa7da1b3a397b9fd3e74949c675d42d8540d3abd1c73c")
      format("woff");
}

@font-face {
  font-family: "ITC New Veljovic";
  font-weight: 700;
  font-style: italic;
  font-display: fallback;
  src: url("https://fonts.shopifycdn.com/itc_new_veljovic/itcnewveljovic_i7.3f6b34cbfe37371b9f9ea68044eced7d9087ec89.woff2?h1=dGhldnliZS1jby5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=dGhldnliZS5jbw&hmac=da5b2396f159eaaf625ff85a0c45d41062a37133c830222648987e614dd0ea35")
      format("woff2"),
    url("https://fonts.shopifycdn.com/itc_new_veljovic/itcnewveljovic_i7.40a4aa6e40f5ca21561b861098ef80be05978899.woff?h1=dGhldnliZS1jby5hY2NvdW50Lm15c2hvcGlmeS5jb20&h2=dGhldnliZS5jbw&hmac=3e49760de26eced1174602deaf98e09c32f6407d069d795d9ddfa9e9b37646ee")
      format("woff");
} */
.overlay {
  background: #363636;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  opacity: 0.5;
  visibility: visible;
}

.swiper-slide-active .hero__wrapper__background {
  animation: myAnimation 0.5s ease-in !important;
}

@keyframes myAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .information .order-3 {
    order: 999 !important;
  }
}

img {
  font-size: 0;
}